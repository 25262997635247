<template>
    <div class="w-full border border-gray-200 dark:border-gray-700 rounded-lg flex items-center py-2 px-3">
        <div class="flex flex-wrap items-start w-full">
            <item-text title="Pasūtījuma nr." :text="item.order_uuid" />
            <item-text title="Pasūtījuma datums" :text="item.order_date" />
        </div>

        <div class="flex">
            <Button icon="open-in-new" @click="openOrder()" />
        </div>
    </div>
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
    name: "InvoiceOrderDetails",
    props: ['item'],
    components: {
        ItemText,
    },
    methods: {
        openOrder() {
            let routeData = this.$router.resolve({path: '/orders/' + this.item.id});
            window.open(routeData.href, '_blank');
        }
    }
}
</script>